import React from 'react'
import styled from 'styled-components'

import { Layout } from '../../components/layout'
import { Footer } from '../../components/footer'
import { Teaser } from '../../components/teaser'

import { Image } from '../../components/common/image'
import { Grid, Row, Col } from '../../components/common/grid'
import { Space } from '../../components/common/space'
import { H4 } from '../../components/common/heading'

const List = styled.ul`
  margin: 0;
`

const ListItem = styled.li`
  color: #69707b;
  text-align: justify;
  margin-bottom: 10px;
`

const title = 'Chimpanzee Yippee Riegel'
const description = 'Energieriegel mit einem hohen Fruchtanteil für Kinder'
const keywords = ''

const PropteinRiegel = () => (
  <Layout title={title} description={description} keywords={keywords}>
    <Teaser title={title} description={description} imageName="yippee" />

    <Grid>
      <Row centered>
        <Col xs={12} md={9}>
          <div>
            <Space bottom={20}>
              <H4>All Natural Yippee Bar (35g)</H4>
            </Space>
            <p>
              Ein natürlicher Riegel mit hohem Frucht- und Getreideanteil! Der
              Chimpanzee Yippee Riegel wurde speziell für Kinder entwickelt und
              ist mit den vielen Ballaststoffen, komplexen Kohlenhydraten und
              Eiweiß die ideale Energiequelle. Der Riegel ist vegan, besteht zu
              einem Großteil aus einem Frucht-Mix und ist selbstverständlich
              frei von jeglichen Geschmacksverstärkern oder anderen künstlichen
              Inhaltsstoffen. Aufgrund der kompakten Größe und der guten
              Haltbarkeit eignen sich die Riegel von Chimpanzee sehr gut zum
              Mitnehmen.
            </p>

            <p>
              Der Chimpanzee Yippee Riegel sorgt für frische Energie und höhere
              Konzentrationsfähigkeit!
            </p>

            <Space top={50} bottom={20}>
              <H4>Geschmacksrichtungen</H4>
            </Space>

            <List>
              <ListItem>Birne & Marille</ListItem>
              <ListItem>Schokolade & Mandeln</ListItem>
            </List>
          </div>
        </Col>
      </Row>

      <Space top={50} bottom={20}>
        <Row centered>
          <Col xs={12} md={9}>
            <H4>Produktbeispiele</H4>
          </Col>
        </Row>
      </Space>

      <Row centered>
        <Col xs={12} sm={4} md={3} bottom={50}>
          <Image name="yippee_birne" />
        </Col>
        <Col xs={12} sm={4} md={3} bottom={50}>
          <Image name="yippee_schokolade" />
        </Col>
        <Col xs={12} sm={4} md={3} bottom={50} />
      </Row>
    </Grid>

    <Footer bottom={50} />
  </Layout>
)

export default PropteinRiegel
